.add-info {
    border: 1px solid lightgray;
    height: 100%;
    min-height: 85vh;
    &__header {
        background: black;
        color: #ffff;
        height: 40px;
        display: flex;
        align-items: center;
        padding-left: 10px;
    }
    &__sub-header {
        background: darkslategrey;
        color: white;
        height: 40px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        vertical-align: middle;
    }
    &__urls {
        color: black;
        text-decoration:none;
        padding-left: 10px;
    }
    &__urls:hover {
        color: #cc0033;
    }
} 

.urls-list {
    list-style-type: "> ";
    color: #cc0033;
    font-weight: bold;
}

.collapsible {
    background-color: #777;
    color: white;
    cursor: pointer;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    display: inline-block;
    border-bottom: 3px solid white;
  }
  
  .active, .collapsible:hover {
    background-color: #555;
  }

  .collapsible:after {
    content: "+";
    /* background-image: url(../../assets/images/arrowUp.png);
    background-size: cover;
    width: 20px;
    height: 20px; */
    font-size: 20px;
    color: white !important;
    float: right;
    font-weight: bolder;
    margin-right: 10px;
  }
  
  .active:after {
    content: "-";
    /* background-image: url(../../assets/images/arrowDown.png);
    background-size: cover;
    width: 20px;
    height: 20px; */
    font-size: 20px;
    color: white !important;
    font-weight: bolder; 
  }
