.creation-step {
    padding: 12px 20px;
    h3 {
        font-size: 24px;
        font-weight: bolder;
        margin-bottom: 50px;
    }
    h5 {
        font-size: 18px;
        font-weight: bolder;
    }
    #text-field__basic {
        height: 40px;
    }
    .MuiInput-root,
    .MuiFormControl-root {
        width: 100%;
    }
    #text-area-basic__error-message,
    #text-field__basic__invalid-message {
        font-size: 12px;
    }
    label {
        width: 100%;
        display: flex;
        align-items: center;
    }
}

.creation-step-one {
    &__radio-button {
        margin: 16px 0;
    }
}

.creation-step-two {
    .MuiInput-root {
        width: 100%;
    }
}

.creation-step-three {
    &__checkbox {
        margin: 16px 0;
    }
}

.expected-account-name {
    background-color: lightgray;
    padding: 8px;
    width: fit-content;
}

.available {
    color: green;
}

.not-available {
    color: red;
}

.creation-step-four {
    height: 650px;
    position: relative;
    &__scroll-container {
        height: 550px;
        width: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    &__form-group {
        margin: 28px 0;
    }
}

.creation-step-five {
    &__section {
        margin: 32px 0;
    }
    h5 {
        display: flex;
        margin-bottom: 20px;
    }
}

.creation-step-six {
    label {
        display: block;
    }
}