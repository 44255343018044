.intro {
  background-image: url('../../assets/images/intro-background.jpg');
  background-size: cover;
  padding: 50px;
  display: flex;
  // align-items: center;
  // justify-content: center;
  height: 100vh;
  position: relative;
  &__inner {
    color: white;
    background-color: rgba(0,0,0,.5);
  }
  img {
    float: right;
    height: 250px;
    position: absolute;
    right: 0px;
  }
}

.intro_body {
  margin-top: 50px;
  line-height: 2;
}

.title {
  font-size: 60px;
  font-weight: bold;
}

.audi-button {
  height: 50px;
  padding: 10px;
  background-color: black;
  color: white;
  border: 1px solid white;
  box-shadow: 2px 2px 5px #888;
  line-height: 1;
}

.audi-button:hover {
  background-color: white;
  color: black;
}

.red-arrow {
  color: #cc0033;
  font-weight: bold;
  margin-left: 5px;
}