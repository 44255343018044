.modal-body {
    min-height: 250px;
}

.modal-header {
    color: #ffff;
    background: black;
}

.modal-footer {
    border-top: none !important;
    margin-top: 30px;
    button {
        margin-left: 16px;
    }
}