.account-list {
    border: 1px solid lightgray;
    height: 100%;
    position: relative;
    overflow: hidden;
    &__header {
        background: black;
        color: #ffff;
        height: 40px;
        display: flex;
        align-items: center;
        padding-left: 10px;
    }
    &__filter {
        align-items: center;
        display: flex;
        padding: 24px 16px;
    }
    &__filter-select {
        margin-right: 12px;
        width: 30%;
        .MuiInput-root {
            width: 100%;
        }
    }
    .MuiFormControl-root {
        width: 60%;
    }
    &__search-btn {
        background: none;
        border: none;
        width: 40px!important;
    }
    button {
        margin: 10px auto;
        display: block;
        width: 70%;
    }
    .MuiTypography-body1 {
        font-family: 'Audi Type Normal';
        div {
            display: flex;
            align-items: center;
        }
    }
    .MuiTreeItem-label {
        padding: 8px 0;
    }
    &__child-item {
        .MuiTreeItem-label {
            padding-left: 16px;
        }
        .MuiTreeItem-root.Mui-selected>.MuiTreeItem-content .MuiTreeItem-label {
            color: #ffff;
            background-color: black;
        }
        .MuiTreeItem-root.Mui-selected>.MuiTreeItem-content .MuiTreeItem-label:hover,
        .MuiTreeItem-root.Mui-selected:focus>.MuiTreeItem-content .MuiTreeItem-label {
            background-color: black;
        }
    }
}
