@import "~bootstrap/scss/bootstrap";
body {
    margin: 0;
    font-family: 'Audi Type Normal', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: "Audi Type Normal";
    src: url("./assets/fonts/AudiTypeVF_4.033.ttf") format("ttf"), url("./assets//fonts//AudiTypeVF_4.033.woff2") format("woff");
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.col {
    padding: 0;
}

input:focus,
textarea:focus,
select:focus {
    outline: none !important;
}