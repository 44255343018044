.imprint {
  padding: 50px;
}
.headline-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.logo {
  width: 100px;
}
