.welcome {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  flex-direction: column;
  &__inner {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 60%;
    flex: 1;
    h1 {
      color: #ffff;
      font-size: 56px;
      font-weight: bolder;
      position: relative;
      margin-bottom: 50px;
      text-shadow: 1px 1px #0000;
    }
    p {
      color: #ffff;
      text-align: center;
      font-weight: bolder;
      font-size: 18px;
      position: relative;
      margin-bottom: 60px;
      text-shadow: 1px 1px #0000;
    }
  }
  &__footer {
    margin-top: auto;
    margin-bottom: 20px;
    align-self: center;
  }
  &__legal {
    color: white !important;
    font-size: 24px !important;
  }

  a {
    text-decoration: none;
  }
}

.welcome::before {
  content: '';
  background-image: url('../../assets/images/audi-bg-02.jpeg');
  background-size: cover;
  opacity: 0.9;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
