.account-and-permissions {
    border: 1px solid lightgray;
    min-height: 100%;
    &__header {
        background: black;
        color: #ffff;
        height: 40px;
        display: flex;
        align-items: center;
        padding-left: 10px;
    }
    &__container {
        padding: 16px;
        max-height: 100vh;
        overflow-y: auto;
    }
    &__form-group {
        padding: 24px;
    }
    label {
        display: flex;
    }
    .MuiInput-root {
        width: 100%;
    }
    &__button-group {
        margin-top: 24px;
        button {
            margin-right: 24px;
        }
    }
    *:not(:focus-within) .sc-cxpSdN .sc-bBHxTw {
        -webkit-transform: scale(0.75) translateY(-0.1ex);
        -ms-transform: scale(0.75) translateY(-0.1ex);
        transform: scale(0.75) translateY(-5.1ex);
    }
}
