.status-card {
  border-radius: 5px;
  margin: 0.8rem;
  padding: 0.4rem 0.4rem 0.4rem 0.6rem;
  border: 1px solid black;
  box-shadow: 2px 2px 2px grey;
  position: relative;
  & h5 {
    font-weight: bold;
  }
  & a {
    color: black;
  }
  & .marker {
    height: 100%;
    width: 5px;
    left: 0px;
    top: 0px;
    position: absolute;
    z-index: -100;
  }
  & .icon {
    background-size: cover;
    position: absolute;
    height: 20px;
    width: 20px;
    top: 0px;
    right: 0px;
    margin: 0.4rem;
  }
}

.status-card.done {
  & .icon {
    background-image: url(../../../assets/images/statusIconDone.png);
  }
  & .marker {
    background-color: green;
  }
}

.status-card.ongoing {
  & .icon {
    background-image: url(../../../assets/images/statusIconOngoing.png);
  }
  & .marker {
    background-color: black;
  }
}

.status-card.open {
  opacity: 0.5;
  & .icon {
    background-image: url(../../../assets/images/statusIconOpen.png);
  }
  & .marker {
    background-color: lightgrey;
  }
}
